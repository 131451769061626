import { CloseOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, SelectOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, DatePicker, Form, Input, Modal, Typography } from 'antd'
import locale from 'antd/locale/de_DE'
import dayjs from 'dayjs'
import 'dayjs/locale/de.js'
import { defaults } from 'lodash'
import React, { MouseEvent, PointerEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { blue, cyan, green, lime, pink, purple, red, violet, yellow } from '../colors'
import { trimValues } from '../lib/strings'
import { FieldId, PatchConfig, PatchName, Plant, useAppStore } from '../stores/app'
import PatchInner from './PatchInner'

dayjs.locale('de')

interface PatchProps {
  name: PatchName
  onClick: (e: MouseEvent<HTMLElement>) => void
  onModalStatusChange: (isOpen: boolean) => void
  isPortrait: boolean
  isSelected: boolean
  config?: PatchConfig
  isLarge?: boolean
}

const dateFormat = 'DD.MM.YYYY'

const Main = styled.div<{ $portrait?: boolean }>`
  position: relative;
  border: 10px solid #b2ffcc;
  padding: 5${({ $portrait }) => ($portrait ? 'vw' : 'vh')};
  border-radius: 10${({ $portrait }) => ($portrait ? 'vw' : 'vh')};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const ColorWrap = styled.div`
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 4px;
`

const ColorSelection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
const ColorButton = styled.div<{ $color: string; $selected: boolean }>`
  background-color: ${({ $color }) => $color};
  height: 20px;
  width: 20px;
  border: ${({ $selected }) => ($selected ? '2px solid #333' : '2px solid transparent')};
  cursor: pointer;
`
const BodyWrap = styled.div`
  margin-top: 16px;
`

const InfoText = styled.div`
  display: flex;
  gap: 8px;
  min-height: 32px;
  align-items: center;
  margin-bottom: 16px;
`

const EditButton = styled(Button).attrs({
  shape: 'circle',
  icon: <EditOutlined />
})`
  position: absolute;
  right: 1%;
  top: 1%;
`

const DrawButtonWrap = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
`
const EraseButton = styled(Button).attrs({
  shape: 'round',
  icon: <DeleteOutlined />
})``

const BackButton = styled(Button).attrs({
  shape: 'round',
  icon: <SelectOutlined />
})``

const CloseButton = styled(Button).attrs({
  shape: 'round',
  icon: <CloseOutlined />
})``

export default function Patch({
  name,
  onClick,
  onModalStatusChange,
  isPortrait,
  isSelected,
  isLarge = false,
  config
}: PatchProps) {
  const [upsertPlant, deletePlant, upsertPatchLayout] = useAppStore((state) => [
    state.upsertPlant,
    state.deletePlant,
    state.upsertPatchLayout
  ])
  const [editMode, setEditMode] = useState(-1)
  const [eraser, setEraser] = useState(false)
  const [selectedColor, setSelectedColor] = useState(0)
  const [form] = Form.useForm<Plant>()

  const selectedPlant = useMemo(() => {
    if (!isSelected) return
    return config?.plants.find(({ colorIndex }) => colorIndex === selectedColor)
  }, [config?.plants, selectedColor, isSelected])

  useEffect(() => {
    if (!isSelected) return
    if (selectedPlant) {
      form.setFieldsValue(selectedPlant)
    } else {
      form.resetFields()
    }
  }, [form, selectedPlant, selectedColor, isSelected])

  useEffect(() => {
    if (!isSelected) return
    if (!config) upsertPatchLayout(name, 0, 0)
  }, [isSelected, config, name, upsertPatchLayout])

  const handlePlantChange = useCallback(() => {
    const updatedPlant = defaults(
      { ...selectedPlant, ...trimValues(form.getFieldsValue()), colorIndex: selectedColor },
      { name: '', notes: '', sowing: dayjs().startOf('day') }
    )
    if (!updatedPlant.name) return
    upsertPlant(name, updatedPlant)
  }, [form, selectedPlant, name, upsertPlant, selectedColor])

  function handleClick(e: MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onClick(e)
  }

  function handleDeletePlant() {
    if (!selectedPlant) return
    deletePlant(name, selectedPlant.id ?? 0)
    setSelectedColor(0)
  }

  function handlePixelClick(index: number) {
    if (!config || editMode < 1) return
    upsertPatchLayout(name, index, eraser ? null : selectedColor)
  }

  function handleColorSelect(e: PointerEvent<HTMLDivElement>) {
    const id = e.currentTarget.getAttribute('id')
    e.currentTarget.focus()
    if (!id) return
    const colorIndex = +id.split('|')[1]
    setSelectedColor(colorIndex)
  }

  function handleColorSelectOk() {
    setEditMode(1)
  }

  function handleColorSelectCancel(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    setEditMode(-1)
  }

  function getModalContainer(): HTMLElement {
    return document.getElementById('modalContainer') ?? document.body
  }

  const fieldId = name.split('|')[0] as FieldId
  return (
    <Main id={name} onClick={handleClick} $portrait={isPortrait}>
      {!!config && (
        <PatchInner
          plants={config.plants}
          layout={config.layout}
          fieldId={fieldId}
          isSelected={isSelected}
          isEditMode={editMode > 0}
          onPixelClick={handlePixelClick}
          isLarge={isLarge}
        />
      )}
      {isSelected && editMode === -1 && <EditButton onClick={() => setEditMode(0)} />}
      {isSelected && editMode > 0 && (
        <DrawButtonWrap>
          <EraseButton danger={eraser} onClick={() => setEraser((prev) => !prev)}>
            Radieren
          </EraseButton>
          <BackButton onClick={() => setEditMode(0)}>Zurück zur Planzenwahl</BackButton>
          <CloseButton onClick={() => setEditMode(-1)}>Zeichenmodus verlassen</CloseButton>
        </DrawButtonWrap>
      )}
      {isSelected && (
        <Modal
          maskClosable={true}
          open={editMode === 0}
          title="Pflanzen bearbeiten"
          getContainer={getModalContainer}
          onCancel={handleColorSelectCancel}
          afterOpenChange={onModalStatusChange}
          footer={() => {
            return (
              <>
                {!!selectedPlant && (
                  <Button key="delete" danger onClick={handleDeletePlant}>
                    Pflanze löschen
                  </Button>
                )}
                <Button key="edit" onClick={handleColorSelectOk} disabled={!selectedColor}>
                  Zeichnen
                </Button>
              </>
            )
          }}
        >
          <ColorWrap>
            <Typography.Text>Nachtschattengewächse</Typography.Text>
            <ColorSelection>
              {red.map((color, index) => (
                <ColorButton
                  key={`c|${index + 1}`}
                  id={`c|${index + 1}`}
                  $color={color}
                  $selected={selectedColor === index + 1}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Kürbisgewächse</Typography.Text>
            <ColorSelection>
              {yellow.map((color, index) => (
                <ColorButton
                  key={`c|${index + 11}`}
                  id={`c|${index + 11}`}
                  $color={color}
                  $selected={selectedColor === index + 11}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Leguminosen</Typography.Text>
            <ColorSelection>
              {lime.map((color, index) => (
                <ColorButton
                  key={`c|${index + 21}`}
                  id={`c|${index + 21}`}
                  $color={color}
                  $selected={selectedColor === index + 21}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Doldenblütler</Typography.Text>
            <ColorSelection>
              {green.map((color, index) => (
                <ColorButton
                  key={`c|${index + 31}`}
                  id={`c|${index + 31}`}
                  $color={color}
                  $selected={selectedColor === index + 31}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Kreuzblütler</Typography.Text>
            <ColorSelection>
              {cyan.map((color, index) => (
                <ColorButton
                  key={`c|${index + 41}`}
                  id={`c|${index + 41}`}
                  $color={color}
                  $selected={selectedColor === index + 41}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Lauchgewächse</Typography.Text>
            <ColorSelection>
              {blue.map((color, index) => (
                <ColorButton
                  key={`c|${index + 51}`}
                  id={`c|${index + 51}`}
                  $color={color}
                  $selected={selectedColor === index + 51}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Korbblütler</Typography.Text>
            <ColorSelection>
              {violet.map((color, index) => (
                <ColorButton
                  key={`c|${index + 61}`}
                  id={`c|${index + 61}`}
                  $color={color}
                  $selected={selectedColor === index + 61}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Gänsefußgewächse</Typography.Text>
            <ColorSelection>
              {purple.map((color, index) => (
                <ColorButton
                  key={`c|${index + 71}`}
                  id={`c|${index + 71}`}
                  $color={color}
                  $selected={selectedColor === index + 71}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          <ColorWrap>
            <Typography.Text>Spezial</Typography.Text>
            <ColorSelection>
              {pink.map((color, index) => (
                <ColorButton
                  key={`c|${index + 81}`}
                  id={`c|${index + 81}`}
                  $color={color}
                  $selected={selectedColor === index + 81}
                  onClick={handleColorSelect}
                />
              ))}
            </ColorSelection>
          </ColorWrap>
          {selectedColor > 0 && (
            <BodyWrap>
              {!selectedPlant && (
                <InfoText>
                  <InfoCircleOutlined />
                  <Typography.Text>Dieser Farbe ist noch keine Planze im aktuellen Feld zugewiesen</Typography.Text>
                </InfoText>
              )}
              <Form form={form} onFieldsChange={selectedPlant?.id ? handlePlantChange : undefined}>
                <Form.Item name="name" label="Name">
                  <Input onBlur={handlePlantChange} />
                </Form.Item>
                <ConfigProvider locale={locale}>
                  <Form.Item name="sowing" label="Gesät/Gepflanzt am">
                    <DatePicker onOk={handlePlantChange} format={dateFormat} allowClear={false} />
                  </Form.Item>
                </ConfigProvider>
                <Form.Item name="notes" label="Notizen">
                  <Input.TextArea rows={4} onBlur={handlePlantChange} />
                </Form.Item>
              </Form>
            </BodyWrap>
          )}
        </Modal>
      )}
    </Main>
  )
}
