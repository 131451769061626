export const green = ['#79f279', '#66cc66', '#53a653', '#408040', '#2d592d']

export const cyan = ['#79f2ca', '#66ccaa', '#53a68a', '#40806a', '#2d594a']

export const blue = ['#79caf2', '#66aacc', '#538aa6', '#406a80', '#2d4a59']

export const violet = ['#7979f2', '#6666cc', '#5353a6', '#404080', '#2d2d59']

export const purple = ['#ca79f2', '#aa66cc', '#8a53a6', '#6a4080', '#4a2d59']

export const pink = ['#f279ca', '#cc66aa', '#a6538a', '#80406a', '#592d4a']

export const red = ['#f27979', '#cc6666', '#a65353', '#804040', '#592e2e']

export const yellow = ['#f2ca79', '#ccaa66', '#a68a53', '#806a40', '#594b2e']

export const lime = ['#caf279', '#aacc66', '#8aa653', '#6a8040', '#4b592e']

export const colors = [
  null,
  ...red,
  null,
  null,
  null,
  null,
  null,
  ...yellow,
  null,
  null,
  null,
  null,
  null,
  ...lime,
  null,
  null,
  null,
  null,
  null,
  ...green,
  null,
  null,
  null,
  null,
  null,
  ...cyan,
  null,
  null,
  null,
  null,
  null,
  ...blue,
  null,
  null,
  null,
  null,
  null,
  ...violet,
  null,
  null,
  null,
  null,
  null,
  ...purple,
  null,
  null,
  null,
  null,
  null,
  ...pink
]
