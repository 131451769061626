import axios, { AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { produce } from 'immer'
import { SignJWT } from 'jose'
import { debounce, isEqual } from 'lodash'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type FieldId = 'NW' | 'NE' | 'SW'
export type PatchSubId = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I'

export type PatchName = `${FieldId}|${PatchSubId}`

export interface Plant {
  id?: number
  sowing: Dayjs
  name: string
  notes: string
  colorIndex: number
}

interface DbPlantCreate extends Omit<Plant, 'patchId' | 'id' | 'sowing' | 'colorIndex'> {
  patch_id: number
  sowing: string
  color_index: number
}

interface DbPlant extends DbPlantCreate {
  id: number
}

export interface PatchConfig {
  id: number
  patch: PatchName
  layout: Array<number | null>
  plants: Plant[]
}

interface DbPatchConfig extends Omit<PatchConfig, 'plants'> {
  season: number
}

interface RetrievePatchConfig extends Omit<PatchConfig, 'plants'> {
  plants: Array<DbPlant & { color_index: number }>
}

interface AppState {
  season: number
  dbReady: boolean
  apiKey: string | null
  initialize: (newApiKey?: string) => Promise<void>
  isInitializing: boolean
  fieldMap: Partial<Record<PatchName, PatchConfig>>
  upsertPatchLayout: (patchName: PatchName, pixelIndex: number, colorIndex: number | null) => Promise<void>
  upsertPlant: (patchName: PatchName, plant: Plant) => Promise<boolean>
  deletePlant: (patchName: PatchName, id: number) => Promise<void>
}

const updatePatchLayout = debounce((id, layout, jwt) => {
  axios
    .patch<undefined, undefined, Partial<DbPatchConfig>>(
      'https://pg.probst.space/veggar_patch',
      { id, layout },
      {
        headers: { Authorization: `bearer ${jwt}`, 'Content-Type': 'application/json; charset=utf-8' },
        params: { id: `eq.${id}` }
      }
    )
    .catch((error) => {
      console.error(error)
    })
}, 1000)

const updatePlant = debounce((patchId, plant, jwt) => {
  const { id, sowing, colorIndex, ...rest } = plant
  axios
    .patch<DbPlant, AxiosResponse<undefined>, DbPlant>(
      'https://pg.probst.space/veggar_plant',
      { id, sowing: dayjs(sowing).format('YYYY-MM-DD'), patch_id: patchId, color_index: colorIndex, ...rest },
      {
        headers: { Authorization: `bearer ${jwt}`, 'Content-Type': 'application/json; charset=utf-8' },
        params: { id: `eq.${id}` }
      }
    )
    .catch((error) => {
      console.error(error)
    })
}, 1000)

async function getJWT(apiKey: string): Promise<string> {
  const secret = new TextEncoder().encode(apiKey)
  const storedJWT = localStorage.getItem('veggar_jwt')
  if (storedJWT) return storedJWT
  return new SignJWT({ role: 'veggar_user' }).setProtectedHeader({ alg: 'HS256' }).setExpirationTime('5m').sign(secret)
}

export const useAppStore = createWithEqualityFn<AppState>(
  (set, get) => ({
    season: new Date().getFullYear(),
    dbReady: false,
    apiKey: localStorage.getItem('veggar_api_key'),
    fieldMap: {},
    isInitializing: false,
    initialize: async (newApiKey?: string) => {
      if (!newApiKey && get().isInitializing) return
      set({ isInitializing: true })
      if (newApiKey) {
        localStorage.setItem('veggar_api_key', newApiKey)
        set({ apiKey: newApiKey })
      }
      const apiKey = newApiKey ?? get().apiKey
      if (!apiKey) return
      try {
        const jwt = await getJWT(apiKey)
        const response = await axios.get<RetrievePatchConfig[]>('https://pg.probst.space/veggar_patch', {
          headers: { Authorization: `bearer ${jwt}`, 'Content-Type': 'application/json; charset=utf-8' },
          params: {
            season: `eq.${get().season}`,
            select: 'id,patch,layout,plants:veggar_plant(id,name,sowing,notes,color_index)'
          }
        })
        const fieldMap = response.data.reduce(
          (result, current) => {
            const plants = current.plants.map(({ sowing, color_index: colorIndex, ...plant }) => ({
              ...plant,
              sowing: dayjs(sowing),
              colorIndex
            }))
            result[current.patch] = { ...current, plants }
            return result
          },
          {} as Partial<Record<PatchName, PatchConfig>>
        )
        console.log(fieldMap)
        set({ fieldMap, dbReady: true })
      } catch (e) {
        console.error(e)
        set({ apiKey: null })
      }
    },
    upsertPatchLayout: async (patchName, pixelIndex, colorIndex) => {
      const { apiKey, fieldMap } = get()
      if (!apiKey) return
      let id = 0
      const config = fieldMap[patchName]
      const layout = [...(config?.layout || [])]
      layout[pixelIndex] = colorIndex
      try {
        const jwt = await getJWT(apiKey)
        if (config?.id) {
          id = config.id
          updatePatchLayout(config.id, layout, jwt)
        } else {
          const response = await axios.post<DbPatchConfig, AxiosResponse<DbPatchConfig[]>, Omit<DbPatchConfig, 'id'>>(
            'https://pg.probst.space/veggar_patch',
            { patch: patchName, layout, season: 2024 },
            {
              headers: {
                Authorization: `bearer ${jwt}`,
                'Content-Type': 'application/json; charset=utf-8',
                Prefer: 'return=representation'
              }
            }
          )
          id = response.data[0].id
        }
      } catch (e) {
        console.error(e)
        return
      }
      set(
        produce<AppState>((state) => {
          state.fieldMap[patchName] = { id, layout, plants: state.fieldMap[patchName]?.plants ?? [], patch: patchName }
        })
      )
    },
    upsertPlant: async (patchName, plant) => {
      let success = false
      const { apiKey, fieldMap } = get()
      const { id, sowing, colorIndex, ...rest } = plant
      const config = fieldMap[patchName]
      if (!config) return false
      const existingPlant = id ? config.plants.find((configPlant) => configPlant.id === id) : undefined
      if (existingPlant && isEqual(existingPlant, plant)) return true
      if (!apiKey) return false
      try {
        const jwt = await getJWT(apiKey)
        if (!id) {
          const response = await axios.post<DbPlant, AxiosResponse<DbPlant[]>, DbPlantCreate>(
            'https://pg.probst.space/veggar_plant',
            { sowing: dayjs(sowing).format('YYYY-MM-DD'), patch_id: config.id, ...rest, color_index: colorIndex },
            {
              headers: {
                Authorization: `bearer ${jwt}`,
                'Content-Type': 'application/json; charset=utf-8',
                Prefer: 'return=representation'
              }
            }
          )
          plant.id = response.data[0].id
        } else {
          updatePlant(config.id, plant, jwt)
        }
      } catch (e) {
        console.error(e)
        return false
      }
      set(
        produce<AppState>((state) => {
          const config = state.fieldMap[patchName]
          if (!config) return
          const index = config.plants.findIndex(({ id }) => id === plant.id)
          if (index < 0) config.plants.push(plant)
          else config.plants[index] = plant
          success = true
        })
      )
      return success
    },
    deletePlant: async (patchName, plantId) => {
      const { apiKey } = get()
      if (!apiKey) return
      try {
        const jwt = await getJWT(apiKey)
        await axios.delete<DbPlant, AxiosResponse<DbPlant>, DbPlantCreate>('https://pg.probst.space/veggar_plant', {
          headers: {
            Authorization: `bearer ${jwt}`,
            'Content-Type': 'application/json; charset=utf-8'
          },
          params: { id: `eq.${plantId}` }
        })
      } catch (e) {
        console.error(e)
      }
      set(
        produce<AppState>((state) => {
          const config = state.fieldMap[patchName]
          if (!config) return
          const index = config.plants.findIndex(({ id }) => id === plantId)
          if (index > -1) config.plants.splice(index, 1)
        })
      )
    }
  }),
  shallow
)
