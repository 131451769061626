import { Button, Form, Input, Modal, Spin } from 'antd'
import React, { PointerEvent, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Patch from './components/Patch'
import { PatchName, PatchSubId, useAppStore } from './stores/app'

type Orientation = 'portrait' | 'landscape'

const Main = styled.div<{ $portrait?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 10${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
  width: 600${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
  height: 600${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
  grid-gap: 20${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
`

const Field = styled.div<{ $threeRow?: boolean; $portrait?: boolean }>`
  background-color: #3b404d;
  border-radius: 10${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
  padding: 10${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
  position: relative;
  display: grid;
  grid-template-rows: 1fr 1fr ${({ $threeRow }) => ($threeRow ? '1fr' : '')};
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15${({ $portrait }) => ($portrait ? 'dvw' : 'dvh')};
`

const ModalContainer = styled.div<{ $isModalOpen: boolean }>`
  position: absolute;
  pointer-events: ${({ $isModalOpen }) => ($isModalOpen ? 'all' : 'none')};
  transform-origin: top left;
  top: 0;
  left: 0;
  .ant-modal-wrap {
    position: absolute;
  }
  .ant-modal-mask {
    position: absolute;
  }
`

const patchSubIds: PatchSubId[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

function App() {
  const [selectedPatch, setSelectedPatch] = useState<PatchName | null>(null)
  const [orientation, setOrientation] = useState<Orientation>(
    window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [zoom, setZoom] = useState({ x: 0, y: 0, scale: 1 })
  const mainSize = useRef(0)
  const [fieldMap, dbReady, apiKey, initialize] = useAppStore((state) => [
    state.fieldMap,
    state.dbReady,
    state.apiKey,
    state.initialize
  ])

  const reScale = useCallback(() => {
    if (!mainSize.current) {
      const mainElement = document.getElementById('main')
      if (!mainElement) {
        console.error('Main element not found!')
        return
      }
      const { width } = mainElement.getBoundingClientRect()
      mainSize.current = width
    }
    if (!selectedPatch) {
      const sizeLimit = Math.min(window.innerWidth, window.innerHeight)
      const mainTargetSize = sizeLimit - sizeLimit * 0.05
      const scale = mainTargetSize / mainSize.current
      const x = (window.innerWidth - mainSize.current * scale) / 2
      const y = (window.innerHeight - mainSize.current * scale) / 2
      setZoom({ x, y, scale })
    } else {
      const element = document.getElementById(selectedPatch)
      if (!element) {
        console.error(`Element ${selectedPatch} not found!`)
        return
      }
      const { x: currentX, y: currentY, scale: currentScale } = zoom
      const { top, left, width, height } = element.getBoundingClientRect()
      const realWidth = width / currentScale
      const realHeight = height / currentScale
      const realLeft = (left - currentX) / currentScale
      const realTop = (top - currentY) / currentScale
      let scale: number
      if (orientation === 'landscape') {
        const target = window.innerHeight - window.innerWidth * 0.05
        scale = target / realHeight
      } else {
        const target = window.innerWidth - window.innerWidth * 0.05
        scale = target / realWidth
      }
      const marginX = (window.innerWidth - realWidth * scale) / 2
      const marginY = (window.innerHeight - realHeight * scale) / 2
      const x = marginX - realLeft * scale
      const y = marginY - realTop * scale
      setZoom({ x, y, scale })
      setTimeout(() => {
        const modalContainer = document.getElementById('modalContainer')
        if (!modalContainer) return
        const modalLeft = realLeft + realWidth / 2 - window.innerWidth / scale / 2
        const modalTop = realTop + realHeight / 2 - window.innerHeight / scale / 2

        modalContainer.style.top = `${modalTop}px`
        modalContainer.style.left = `${modalLeft}px`
        modalContainer.style.width = `${window.innerWidth}px`
        modalContainer.style.height = `${window.innerHeight}px`
        modalContainer.style.scale = (1 / scale).toString()
      }, 800)
    }
  }, [selectedPatch, orientation])

  useEffect(() => {
    if (!dbReady) {
      void initialize()
      return
    }
    if (selectedPatch) {
      document.body.style.transition = 'scale 800ms ease, translate 800ms ease'
    }
    reScale()
  }, [selectedPatch, dbReady, initialize, reScale])

  useEffect(() => {
    const { x, y, scale } = zoom
    document.body.style.scale = scale.toString()
    document.body.style.translate = `${x}px ${y}px`
  }, [zoom])

  useEffect(() => {
    const handleResize = () => {
      const current: Orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
      if (current !== orientation) setOrientation(current)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [orientation])

  useEffect(() => {
    function listener() {
      setSelectedPatch(null)
    }
    window.addEventListener('click', listener)
    return () => {
      window.removeEventListener('click', listener)
    }
  }, [])

  const handleModalStatusChange = useCallback((isOpen: boolean) => {
    setIsModalOpen(isOpen)
  }, [])

  const handleZoom = useCallback((e: PointerEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const element = e.currentTarget
    const patchName = element.getAttribute('id') as PatchName
    setSelectedPatch(patchName)
  }, [])

  function handleSetApiKey(values: { apiKey: string }) {
    void initialize(values.apiKey)
  }

  const isPortrait = orientation === 'portrait'

  if (!apiKey)
    return (
      <Main>
        <Modal open={!apiKey} closable={false} footer={false}>
          <Form onFinish={handleSetApiKey}>
            <Form.Item label="Veggar API Key" name="apiKey">
              <Input />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Speichern
              </Button>
            </div>
          </Form>
        </Modal>
      </Main>
    )

  if (!dbReady) return <Spin size="large" />

  return (
    <Main id="main" $portrait={isPortrait}>
      <Field $portrait={isPortrait}>
        {patchSubIds.slice(0, 6).map((id) => {
          const patchId: PatchName = `NW|${id}`
          const config = fieldMap[patchId]
          return (
            <Patch
              key={patchId}
              name={patchId}
              onClick={handleZoom}
              onModalStatusChange={handleModalStatusChange}
              isPortrait={isPortrait}
              isSelected={selectedPatch === patchId}
              config={config}
              isLarge
            />
          )
        })}
      </Field>
      <Field $threeRow $portrait={isPortrait}>
        {patchSubIds.map((id) => {
          const patchId: PatchName = `NE|${id}`
          const config = fieldMap[patchId]
          return (
            <Patch
              key={patchId}
              name={patchId}
              onClick={handleZoom}
              onModalStatusChange={handleModalStatusChange}
              isPortrait={isPortrait}
              isSelected={selectedPatch === patchId}
              config={config}
            />
          )
        })}
      </Field>
      <Field $threeRow $portrait={isPortrait}>
        {patchSubIds.map((id) => {
          const patchId: PatchName = `SW|${id}`
          const config = fieldMap[patchId]
          return (
            <Patch
              key={patchId}
              name={patchId}
              onClick={handleZoom}
              onModalStatusChange={handleModalStatusChange}
              isPortrait={isPortrait}
              isSelected={selectedPatch === patchId}
              config={config}
            />
          )
        })}
      </Field>
      <ModalContainer id="modalContainer" $isModalOpen={isModalOpen} />
    </Main>
  )
}

export default App
