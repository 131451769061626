import React, { PointerEvent } from 'react'

import { Tooltip } from 'antd'
import { keyBy } from 'lodash'
import range from 'lodash/range'
import styled from 'styled-components'
import { colors } from '../colors'
import { FieldId, Plant } from '../stores/app'

interface PatchInnerProps {
  fieldId: FieldId
  layout: Array<number | null>
  isSelected: boolean
  isEditMode: boolean
  onPixelClick: (index: number) => void
  plants: Plant[]
  isLarge: boolean
}

const Pixel = styled.div<{
  $leftNeighbor: boolean
  $topNeighbor: boolean
  $rightNeighbor: boolean
  $bottomNeighbor: boolean
  $color: string | null
  $edit: boolean
}>`
  ${({ $color }) => ($color ? `background-color: ${$color};` : '')};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
  ${({ $leftNeighbor, $topNeighbor, $rightNeighbor, $bottomNeighbor }) => `
    ${!$topNeighbor && !$leftNeighbor ? 'border-top-left-radius: 32px;' : ''}
    ${!$topNeighbor && !$rightNeighbor ? 'border-top-right-radius: 32px;' : ''}
    ${!$bottomNeighbor && !$leftNeighbor ? 'border-bottom-left-radius: 32px;' : ''}
    ${!$bottomNeighbor && !$rightNeighbor ? 'border-bottom-right-radius: 32px;' : ''}
  `};
  position: relative;
  ${({ $edit }) =>
    $edit
      ? `
    &::before {
      content: '';
      border: 1px solid #9a9a9a;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  `
      : ''}
`

function getTooltipContent(plant?: Plant): string | undefined {
  if (!plant) return undefined
  return `${plant.name}\ngesät/geplanzt: ${plant.sowing.format('DD.MM.YYYY')}\n${plant.notes}`
}

export default function PatchInner({ layout, isSelected, isEditMode, onPixelClick, plants, isLarge }: PatchInnerProps) {
  function handlePixelClick(e: PointerEvent<HTMLDivElement>) {
    if (!isSelected) return
    e.stopPropagation()
    const index = +(e.currentTarget.getAttribute('id')?.substring(1) ?? -1)
    if (index < 0) return
    onPixelClick(index)
  }

  const plantsByColor = keyBy(plants, 'colorIndex')

  return (
    <>
      {range(isLarge ? 216 : 144).map((index) => {
        const colorIndex = layout[index]
        const plant = colorIndex ? plantsByColor[colorIndex] : undefined
        if (isSelected && !isEditMode) {
          return (
            <Tooltip
              key={`p${index}`}
              overlayStyle={{ whiteSpace: 'pre-line' }}
              open={isEditMode ? false : undefined}
              title={getTooltipContent(plant)}
            >
              <Pixel
                id={`p${index}`}
                $color={colorIndex ? colors[colorIndex] : null}
                $leftNeighbor={index % 12 > 0 && !!layout[index - 1]}
                $rightNeighbor={index % 12 !== 11 && !!layout[index + 1]}
                $topNeighbor={index >= 12 && !!layout[index - 12]}
                $bottomNeighbor={index < (isLarge ? 204 : 132) && !!layout[index + 12]}
                $edit={isEditMode}
              />
            </Tooltip>
          )
        }
        return (
          <Pixel
            key={`p${index}`}
            id={`p${index}`}
            $color={colorIndex ? colors[colorIndex] : null}
            $leftNeighbor={index % 12 > 0 && !!layout[index - 1]}
            $rightNeighbor={index % 12 !== 11 && !!layout[index + 1]}
            $topNeighbor={index >= 12 && !!layout[index - 12]}
            $bottomNeighbor={index < (isLarge ? 204 : 132) && !!layout[index + 12]}
            $edit={isEditMode}
            onClick={isSelected ? handlePixelClick : undefined}
          />
        )
      })}
    </>
  )
}
